import { Icon } from "@iconify/react";
import { Box, SxProps, Theme } from "@mui/material";
import { UserRole } from "../../enum/common";

interface Props {
  name: string;
  sx?: SxProps<Theme>;
}

const IconBox: React.FC<Props> = ({ name, sx }) => {
  return (
    <Box
      component="span"
      className="svg-color"
      sx={{
        width: 24,
        height: 24,
        display: "inline-block",
        ...sx,
      }}
    >
      <Icon icon={name} width={"100%"} />
    </Box>
  );
};

const navConfig = [
  {
    title: "dashboard",
    path: "/",
    icon: <IconBox name="carbon:dashboard" />,
    roles: [UserRole.Admin, UserRole.Manager, UserRole.Couch],
  },
  {
    title: "incomes",
    path: "/incomes",
    icon: (
      <IconBox name="material-symbols-light:price-change-outline-rounded" />
    ),
    roles: [UserRole.Admin, UserRole.Manager, UserRole.Couch],
  },
  {
    title: "Pending Incomes",
    path: "/pending-incomes",
    icon: (
      <IconBox name="material-symbols-light:price-change-outline-rounded" />
    ),
    roles: [UserRole.Admin],
  },
  {
    title: "payments",
    path: "/payments",
    icon: <IconBox name="solar:money-bag-linear" />,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    title: "expenses",
    path: "/expenses",
    icon: <IconBox name="uil:money-insert" />,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    title: "athletes",
    path: "/athletes",
    icon: <IconBox name="icon-park-outline:sport" />,
    roles: [UserRole.Admin, UserRole.Manager, UserRole.Couch],
  },
  {
    title: "couches",
    path: "/couches",
    icon: <IconBox name="mdi:teach-poll" />,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    title: "plans",
    path: "/plans",
    icon: <IconBox name="icon-park-outline:plan" />,
    roles: [UserRole.Admin, UserRole.Manager, UserRole.Couch],
  },
  {
    title: "snapshots",
    path: "/snapshots",
    icon: <IconBox name="fluent-mdl2:report-library" />,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    title: "Notifications (MP)",
    path: "/mp-notifications",
    icon: <IconBox name="simple-icons:mercadopago" />,
    roles: [UserRole.Admin],
  },
];

export default navConfig;
